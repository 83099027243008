import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { getCheckout, getCheckoutLoadingState } from "../../redux/modules/checkout";
import { getPrepaidSuccess, getPurchaseType } from "../../redux/modules/paymentOptions";
import { startNewPurchase } from "../../redux/modules/session";

class PaymentSuccess extends React.Component {
  returnToStore = () => {
    this.props.startNewPurchase();
    this.context.router.history.push("/");
  };

  titleMessage = () => {
    if (this.props.prepaidSuccess) {
      return <FormattedMessage id="prepaid_code_redeemed" defaultMessage="Code Redeemed!" />;
    } else {
      return <FormattedMessage id="success" defaultMessage="Success!" />;
    }
  };

  render() {
    const { checkout, loading } = this.props;
    const totalVc = checkout.purchasedPricePoint?.virtualAmount + checkout.purchasedPricePoint?.virtualBonusAmount;
    const pricePointTier = sessionStorage.getItem("pricePointTier") ?? "1";

    return (
      <div>
        {loading ? (
          <div className="container loading">
            <div className="spinner" />
          </div>
        ) : (
          <div className="container payment-complete fade-in">
            <h1>
              {this.titleMessage()}
            </h1>
            <div className={`rpCard priceTier${pricePointTier}`}></div>
            {checkout.purchasedPricePoint?.virtualAmount && (
              <>
                <div className="vcTotalFormatted">
                  <div className="total-vc">
                    <div className="vc-icon" />
                    {totalVc}
                    <FormattedMessage
                      id={`vc_name_${checkout.purchasedPricePoint?.virtualCurrencyCode.toLowerCase()}`}
                      defaultMessage={checkout.purchasedPricePoint?.virtualCurrencyName}
                    />
                  </div>
                </div>
              </>
            )}
            {checkout.newBalance && (
              <div className="payment-balance">
                <FormattedMessage id={`new_balance`} defaultMessage={`New Balance`} />:
                <div className="vc-icon" />
                <span className="new-balance">
                  {`${checkout.newBalance} `}
                  <FormattedMessage
                    id={`vc_name_${checkout.purchasedPricePoint?.virtualCurrencyCode.toLowerCase()}`}
                    defaultMessage={checkout.purchasedPricePoint?.virtualCurrencyName}
                  />
                </span>
              </div>
            )}
            {checkout.playerFacingId && (
              <div className="transaction text-primary">
                <FormattedMessage id="transaction_id" defaultMessage="Transaction Id" />:{checkout.playerFacingId}
              </div>
            )}
            <button className="btn btn-secondary" onClick={this.returnToStore}>
              <FormattedMessage id="return_to_store" defaultMessage="Return to Store" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

PaymentSuccess.propTypes = {
  checkout: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  prepaidSuccess: PropTypes.bool,
  purchaseType: PropTypes.string
};

PaymentSuccess.contextTypes = {
  router: PropTypes.object
};

const mapStateToProps = (state) => ({
  checkout: getCheckout(state),
  loading: getCheckoutLoadingState(state),
  prepaidSuccess: getPrepaidSuccess(state),
  purchaseType: getPurchaseType(state)
});

export default connect(mapStateToProps, { startNewPurchase })(PaymentSuccess);