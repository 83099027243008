import iconMap from "./iconMap.json";

export default function(paymentMethod, platform, country, locale, currency) {
  const lowerPlatform = platform.toLowerCase();
  const countryCode = (country && country.code3) ? country.code3.toLowerCase() : "";
  const language = locale ? locale.substring(0, 2) : "";
  const priority0 = `${paymentMethod}.${lowerPlatform}.${countryCode}`; //ex: prepaidcard.eun1.fra
  const priority1 = `${paymentMethod}.${countryCode}.${language}`;      //ex: prepaidcard.fra.fr
  const priority2 = `${paymentMethod}.${lowerPlatform}.${language}`;    //ex: prepaidcard.eun1.fr
  const priority3 = `${paymentMethod}.${language}`;                     //ex: prepaidcard.fr
  const priority4 = `${paymentMethod}.${countryCode}`;                  //ex: prepaidcard.fra
  const priority5 = `${paymentMethod}.${currency}`;                     //ex: prepaidcard.usd
  const priority6 = `${paymentMethod}.${lowerPlatform}`;                //ex: prepaidcard.eun1
  const priority7 = `${paymentMethod}`;                                 //ex: prepaidcard

  const priorities = [
    priority0,
    priority1,
    priority2,
    priority3,
    priority4,
    priority5,
    priority6,
    priority7
  ];

  for (const priority of priorities) {
    if (iconMap[priority]) {
      return `/images/pm/${iconMap[priority]}`;
    }
  }

  return undefined;
}
