import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, FormattedHTMLMessage } from "react-intl";
import PaymentOptionsFooter from "../PaymentOptionsFooter";
import PaymentOptionsSidebar from "../../components/PaymentOptionsSidebar";
import ProgressBar from "../../components/v2/ProgressBar";
import KrPaymentOptions from "../../components/kr/KrPaymentOptions";
import Tooltip from "../../components/Tooltip";
import classNames from "classnames";

import {
  selectPaymentMethod,
  loadPaymentOptionsV2,
  confirmDirectPurchase,
  confirmDirectPurchaseV2,
  confirmPricePointPurchaseV2,
  getPaymentOptionsView,
  getOptionsLoadingState,
  getGame,
  getPlatform,
  getStorefront,
  getPurchaseAmount,
  getPurchaseCurrency,
  getPurchaseCurrencyName,
  getMinVirtualAmount,
  getCheckoutUrl,
  getPurchaseType,
  getSelectedPaymentMethodId,
  getShowVat,
  getTaxDisclaimer,
  isDirectPurchase,
  getShowEuRightToCancel,
  getCommonPaymentMethodId,
  getCancelUrl,
  getSelectedPricePointId,
  selectPricePoint,
  PREPAID_CARD,
  getOptionsLoadedState
} from "../../redux/modules/paymentOptions";
import { getCurrentCountry } from "../../redux/modules/user";
import {
  getSessionToken,
  getSessionIsKorea,
  getSessionCurrentPurchaseId,
  getSessionStoreCode,
  getSessionPricePointId,
  initSessionPricePointId
} from "../../redux/modules/session";

import { getPurchaseContext } from "../../redux/modules/purchaseContext";
import EuRightToCancel from "../EuRightToCancel";
import PricePointView, { getPricePointsForPaymentMethod } from "./PricePointView";
import resolveIcon from "../../utils/iconResolver";

const FOOTER_EXEMPT_SFACS = ["lol-vn2"];
const DEFAULT_PAYMENT_METHODS_SHOWN = 4;

class PaymentMethodSelection extends React.Component {
  state = {
    canStartPurchase: true,
    attemptedPurchase: false,
    showAllPaymentMethods: false,
    displayPricePoints: false,
    previousPricePointId: this.props.selectedPricePointId,
    displayTooltip: false
  };

  async componentDidMount() {
    const { loading, loaded, sessionPricePointId } = this.props;

    // only load if necessary (e.g. if the user navigates to this page from the payment failed page)
    if (!loading && !loaded) {
      // loading the payment options also sets the selected price point to the first one in the list,
      // so we wait until this is done before setting the price point from the session state
      await this.props.loadPaymentOptionsV2();

      // refresh redux state with the price point from the session state
      this.props.selectPricePoint(sessionPricePointId);

      // update local state
      this.setState({ previousPricePointId: sessionPricePointId });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.purchaseType) {
      sessionStorage.setItem("purchaseType", nextProps.purchaseType);
    }

    if (nextProps.checkoutUrl && !nextProps.isKorea) {
      window.location = nextProps.checkoutUrl;
    }
  }

  onContinueButtonClicked = () => {
    if (!this.state.canStartPurchase) {
      return;
    }

    const { paymentOptions, selectedPaymentMethodId, selectedPricePointId } = this.props;
    const pricePointsForPaymentMethod = getPricePointsForPaymentMethod(paymentOptions, selectedPaymentMethodId);

    // a price point is valid if it exists in the list of price points for the selected payment method
    const validPricePointForPurchase = pricePointsForPaymentMethod.some(
      (pricePoint) => pricePoint.id === selectedPricePointId
    );

    if (validPricePointForPurchase) {
      const pricePointTier =
        pricePointsForPaymentMethod.findIndex((pricePoint) => pricePoint.id === selectedPricePointId) + 1;
      sessionStorage.setItem("pricePointTier", pricePointTier);
      this.startPurchase();
    } else {
      // prompt the player to select a different price point
      const defaultPricePointId = pricePointsForPaymentMethod[0]?.id ?? null;
      this.onPricePointSelect(defaultPricePointId);
      this.setState({ displayPricePoints: true });
    }
  };

  startPurchase = () => {
    // also persist the pricepoint in session storage for when we redirect to the checkout page and back to this page
    const { selectedPricePointId, selectedPaymentMethodId } = this.props;
    this.props.initSessionPricePointId(selectedPricePointId);

    const commonPaymentMethodId = getCommonPaymentMethodId(selectedPaymentMethodId);
    this.props.confirmPricePointPurchaseV2(commonPaymentMethodId, selectedPricePointId);
  };

  onBackButtonClicked = () => {
    // either go back to the payment method selection or the previous page, based on what is currently being displayed
    if (this.state.displayPricePoints) {
      // Reset the price point to the previous one
      this.onPricePointSelect(this.state.previousPricePointId);
      this.setState({
        displayPricePoints: false
      });
    } else {
      // Navigate to the bundle selection page
      this.context.router.history.push("/");
    }
  };

  renderFooter = () => {
    const showEuRightToCancel = this.props.showEuRightToCancel;
    if (showEuRightToCancel || FOOTER_EXEMPT_SFACS.includes(this.props.storefrontAccountCode)) {
      return (
        <div className={"main terms-container"}>
          {showEuRightToCancel && (
            <EuRightToCancel
              setCanStartPurchase={this.setCanStartPurchase}
              attemptedPurchase={this.state.attemptedPurchase}
            />
          )}
        </div>
      );
    }

    const hasPaymentOptions = this.props.paymentOptions.length > 0;

    if (hasPaymentOptions) {
      return (
        <PaymentOptionsFooter
          virtualCurrency={this.props.purchaseCurrency}
          showTaxDisclaimer={this.props.taxDisclaimer}
          isDirectPurchase={isDirectPurchase(this.props.purchaseType)}
        />
      );
    }
  };

  setCanStartPurchase = (canStartPurchase = false) => {
    this.setState({ canStartPurchase });
  };

  attemptStartPurchase = () => {
    this.setState({ attemptedPurchase: true });
  };

  isContinueDisabled = () => {
    // Keep the Continue button disabled until a purchase can be started (must check EU legal checkbox if in EU)
    return !this.state.canStartPurchase;
  };

  handleTooltipVisibilityChange = (isVisible) => {
    if (this.isContinueDisabled()) {
      this.setState({ displayTooltip: isVisible });
    }
  };

  getPaymentOptions = () => {
    // players can only access the prepaid cards page from the bundle selection page, not this page
    const paymentOptions = this.props.paymentOptions.filter(
      (paymentOption) => paymentOption.paymentMethodId !== PREPAID_CARD
    );

    if (paymentOptions.length <= DEFAULT_PAYMENT_METHODS_SHOWN) {
      return paymentOptions;
    }

    return this.state.showAllPaymentMethods ? paymentOptions : paymentOptions.slice(0, DEFAULT_PAYMENT_METHODS_SHOWN);
  };

  renderOptionsToggle = () => {
    const paymentOptions = this.props.paymentOptions;
    if (paymentOptions.length <= DEFAULT_PAYMENT_METHODS_SHOWN) {
      return null;
    }

    const id = this.state.showAllPaymentMethods ? "see_less_options" : "see_more_options";
    const message = this.state.showAllPaymentMethods ? "SEE LESS OPTIONS" : "SEE MORE OPTIONS";

    return (
      <button
        className={classNames("toggle-options", this.state.showAllPaymentMethods ? "show-less" : "show-more")}
        onClick={this.toggleOptions}
      >
        <FormattedMessage id={id} defaultMessage={message} />
      </button>
    );
  };

  toggleOptions = () => {
    const showAllPaymentMethods = this.state.showAllPaymentMethods;
    this.setState({ showAllPaymentMethods: !showAllPaymentMethods });
  };

  renderContinueLinks() {
    return (
      <div className={"payment-method-button-row"}>
        <div className={classNames("back-container", { loading: this.props.loading })}>
          <button className="btn btn-secondary" onClick={this.onBackButtonClicked}>
            <FormattedMessage id="button_back" defaultMessage="Back" />
          </button>
        </div>
        <div
          className={classNames("continue-container", {
            loading: this.props.loading
          })}
        >
          <Tooltip
            placement="right"
            trigger="hover"
            showArrow={true}
            onVisibilityChange={this.handleTooltipVisibilityChange}
            tooltipShown={this.state.displayTooltip}
            tooltipBody={
              <FormattedMessage id="tooltip_terms" defaultMessage="Agree to the terms to continue" />
            }
          >
            <button
              className="btn btn-primary"
              onClick={this.onContinueButtonClicked}
              disabled={this.isContinueDisabled()}
            >
              <FormattedMessage id="next_step" defaultMessage="Next Step" />
            </button>
          </Tooltip>
        </div>
      </div>
    );
  }

  onPricePointSelect = (id) => {
    this.props.selectPricePoint(id);
  };

  // Renders the payment method list
  // or price point view if the currently selected payment method doesn't contain the selected price point
  renderMainContent() {
    const { currentCountry, selectedPaymentMethodId, taxDisclaimer, platform } = this.props;

    const {
      intl: { locale }
    } = this.context;

    const paymentOptions = this.getPaymentOptions();
    const paymentMethodId = getCommonPaymentMethodId(selectedPaymentMethodId);

    // Payment method list view
    if (!this.state.displayPricePoints) {
      return (
        <div className="payment-options fade-in">
          <PaymentOptionsSidebar
            paymentOptions={paymentOptions}
            selectedPaymentMethodId={selectedPaymentMethodId}
            currentCountry={currentCountry}
            onPaymentMethodSelect={this.props.selectPaymentMethod}
            platform={this.props.platform}
            taxDisclaimer={taxDisclaimer}
          />
          {this.renderOptionsToggle()}
        </div>
      );
    }

    const pricePoints = getPricePointsForPaymentMethod(paymentOptions, selectedPaymentMethodId);
    const currency = pricePoints[0]?.realCurrencyCode?.toLowerCase() ?? "";

    // When the player needs to select a different price point for their selected payment method
    return (
      <div className="alternative-bundle-selection">
        <div className="header">
          <img
            src={`${resolveIcon(paymentMethodId, platform, currentCountry, locale, currency)}`}
            alt={paymentMethodId}
          />
          <span>
            <FormattedHTMLMessage
              id="alternative_bundle_selection_prompt"
              defaultMessage="Your bundle options have changed due to the payment method selected.<br />Please update your selection:"
            />
          </span>
        </div>
        <PricePointView onPricePointClick={this.onPricePointSelect} attemptStartPurchase={this.attemptStartPurchase} />
      </div>
    );
  }

  render() {
    const { loading, loaded, isKorea, sessionToken, checkoutUrl, purchaseContext } = this.props;

    const paymentOptions = this.getPaymentOptions();
    if (isKorea) {
      return (
        <KrPaymentOptions
          paymentOptions={paymentOptions}
          checkoutUrl={checkoutUrl}
          sessionToken={sessionToken}
          purchaseContext={purchaseContext}
        />
      );
    }

    return (
      <div className="payment-flow">
        <ProgressBar step={2} />
        {loading || !loaded ? (
          <div className="container loading">
            <div className="loading-spinner" />
          </div>
        ) : (
          <div className="payment-method-container">
            {this.renderMainContent()}
            {this.renderFooter()}
            {this.renderContinueLinks()}
          </div>
        )}
      </div>
    );
  }
}

PaymentMethodSelection.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  loadPaymentOptionsV2: PropTypes.func.isRequired,
  confirmDirectPurchase: PropTypes.func.isRequired,
  confirmPricePointPurchaseV2: PropTypes.func.isRequired,
  currentCountry: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  location: PropTypes.object,
  sessionToken: PropTypes.string.isRequired,
  currentPurchaseId: PropTypes.string,
  sessionPricePointId: PropTypes.string,
  game: PropTypes.string.isRequired,
  storefrontAccountCode: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  purchaseAmount: PropTypes.number,
  purchaseCurrency: PropTypes.string,
  purchaseCurrencyName: PropTypes.string,
  minVirtualAmount: PropTypes.number,
  checkoutUrl: PropTypes.string,
  cancelUrl: PropTypes.string,
  purchaseType: PropTypes.string,
  selectedPaymentMethodId: PropTypes.string,
  selectedPricePointId: PropTypes.string,
  showVat: PropTypes.bool,
  isKorea: PropTypes.bool.isRequired,
  purchaseContext: PropTypes.object
};

const mapStateToProps = (state) => ({
  paymentOptions: getPaymentOptionsView(state),
  currentCountry: getCurrentCountry(state),
  loading: getOptionsLoadingState(state),
  loaded: getOptionsLoadedState(state),
  sessionToken: getSessionToken(state),
  currentPurchaseId: getSessionCurrentPurchaseId(state),
  sessionPricePointId: getSessionPricePointId(state),
  game: getGame(state),
  storefrontAccountCode: getStorefront(state),
  platform: getPlatform(state),
  storeCode: getSessionStoreCode(state),
  purchaseAmount: getPurchaseAmount(state),
  purchaseCurrency: getPurchaseCurrency(state),
  purchaseCurrencyName: getPurchaseCurrencyName(state),
  minVirtualAmount: getMinVirtualAmount(state),
  checkoutUrl: getCheckoutUrl(state),
  cancelUrl: getCancelUrl(state),
  purchaseType: getPurchaseType(state),
  selectedPaymentMethodId: getSelectedPaymentMethodId(state),
  selectedPricePointId: getSelectedPricePointId(state),
  showVat: getShowVat(state),
  taxDisclaimer: getTaxDisclaimer(state),
  showEuRightToCancel: getShowEuRightToCancel(state),
  isKorea: getSessionIsKorea(state),
  purchaseContext: getPurchaseContext(state)
});

PaymentMethodSelection.contextTypes = {
  router: PropTypes.object,
  intl: PropTypes.object.isRequired
};

export default connect(mapStateToProps, {
  loadPaymentOptionsV2,
  selectPaymentMethod,
  selectPricePoint,
  confirmDirectPurchase,
  confirmDirectPurchaseV2,
  confirmPricePointPurchaseV2,
  initSessionPricePointId
})(PaymentMethodSelection);
