import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import classNames from "classnames";

const ProgressBar = ({ step }) => (
  <div className="progress-bar">
    <div className={classNames("progress-bar-step", { completed: step > 1, active: step === 1 })}>
      <div className="step-dot"></div>
      <div className="step-name">
        <FormattedMessage id="step_name" defaultMessage="Step 1" values={{step_number: 1}}/>
      </div>
      <div className="step-description">
        <FormattedMessage
          id="step1_description"
          defaultMessage="Select RP"
          values={{ vc: <FormattedMessage id="vc_name_rp" defaultMessage="RP" /> }}
        />
      </div>
    </div>
    <div className={classNames("progress-bar-step", { completed: step > 2, active: step === 2 })}>
      <div className="step-dot"></div>
      <div className="step-name">
        <FormattedMessage id="step_name" defaultMessage="Step 2" values={{step_number: 2}}/>
      </div>
      <div className="step-description">
        <FormattedMessage id="step2_description" defaultMessage="Select Payment" />
      </div>
    </div>
    <div className={classNames("progress-bar-step", { completed: step > 3, active: step === 3 })}>
      <div className="step-dot"></div>
      <div className="step-name">
        <FormattedMessage id="step_name" defaultMessage="Step 3" values={{step_number: 3}}/>
      </div>
      <div className="step-description">
        <FormattedMessage id="step3_description" defaultMessage="Order Complete" />
      </div>
    </div>
  </div>
);

ProgressBar.propTypes = {
  step: PropTypes.number.isRequired
};

export default ProgressBar;
