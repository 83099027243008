import PropTypes from "prop-types";
import React from "react";
import { FormattedMessage } from "react-intl";
import PaymentMethodList from "../components/PaymentMethodList";
import CountryChangeList from "../components/CountryChangeList";
import { connect } from "react-redux";
import { getSessionIsNewFlow } from "../redux/modules/session";

class PaymentOptionsSidebar extends React.Component {
  render() {
    const {
      selectedPaymentMethodId,
      paymentOptions,
      platform,
      currentCountry,
      countries,
      onPaymentMethodSelect,
      onChangeCountry,
      onModalOpen,
      allowCountryChange,
      isNewFlow
    } = this.props;

    return (
      <div className="sidebar">
        <h2>
          <FormattedMessage id="order_step1" defaultMessage="Select Purchase Method" />
        </h2>
        <PaymentMethodList
          paymentOptions={paymentOptions}
          onPaymentMethodSelect={onPaymentMethodSelect}
          country={currentCountry}
          platform={platform}
          selectedPaymentMethodId={selectedPaymentMethodId}
        />
        {(typeof allowCountryChange === "undefined" || allowCountryChange) && countries?.length > 0 && !isNewFlow && (
          <CountryChangeList
            countries={countries}
            currentCountry={currentCountry}
            onChangeCountry={onChangeCountry}
            onModalOpen={onModalOpen}
          />
        )}
      </div>
    );
  }
}

PaymentOptionsSidebar.propTypes = {
  paymentOptions: PropTypes.array.isRequired,
  selectedPaymentMethodId: PropTypes.string.isRequired,
  platform: PropTypes.string.isRequired,
  countries: PropTypes.array,
  currentCountry: PropTypes.object.isRequired,
  onChangeCountry: PropTypes.func,
  onModalOpen: PropTypes.func,
  onPaymentMethodSelect: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state)
});

export default connect(mapStateToProps)(PaymentOptionsSidebar);
