import PropTypes from "prop-types";
import React from "react";
import TooltipTrigger from "react-popper-tooltip";

const Tooltip = ({ tooltipBody, children, showArrow = false, ...props }) => (
  <TooltipTrigger
    {...props}
    tooltip={({ arrowRef, tooltipRef, getArrowProps, getTooltipProps, placement }) => (
      <div
        {...getTooltipProps({
          ref: tooltipRef,
          className: showArrow && `tooltip-container ${placement}`
        })}
      >
        {showArrow && (
          <div
            {...getArrowProps({
              ref: arrowRef,
              className: "tooltip-arrow",
              "data-placement": placement
            })}
          />
        )}
        {tooltipBody}
      </div>
    )}
  >
    {({ getTriggerProps, triggerRef }) => (
      <span
        {...getTriggerProps({
          ref: triggerRef
        })}
      >
        {children}
      </span>
    )}
  </TooltipTrigger>
);

Tooltip.propTypes = {
  tooltipBody: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  showArrow: PropTypes.bool,
  placement: PropTypes.string.isRequired,
  trigger: PropTypes.string.isRequired,
  tooltipShown: PropTypes.bool,
  onVisiblityChange: PropTypes.func,
  followCursor: PropTypes.bool
};

export default Tooltip;
