import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { resetErrorMessage } from "../redux/modules/error";
import queryString from "query-string";
import PaymentError from "../components/PaymentError";
import PaymentExpired from "../components/PaymentExpired";
import { getSessionIsNewFlow } from "../redux/modules/session";

/* eslint-disable react/prefer-stateless-function */
class PaymentFailed extends React.Component {
  state = {};

  componentWillMount() {
    const query = queryString.parse(this.props.location.search);
    if (query.customerror) {
      this.setState({
        errorCode: query.customerror
      });
    }
  }

  onAckError = () => {
    this.props.resetErrorMessage();

    if (this.props.isNewFlow) {
      this.context.router.history.push("/payment-method-selection");
    } else {
      this.context.router.history.push("/");
    }
  };

  render() {
    if (this.state.errorCode === "payment_expired_text") {
      return <PaymentExpired />;
    } else {
      return (
        <PaymentError
          errorCode={this.state.errorCode}
          onAckError={this.onAckError}
        />
      );
    }
  }
}

PaymentFailed.contextTypes = {
  router: PropTypes.object
};

PaymentFailed.propTypes = {
  resetErrorMessage: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state)
})

export default connect(
  mapStateToProps,
  { resetErrorMessage }
)(PaymentFailed);
