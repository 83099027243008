import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { Redirect } from "react-router-dom";
import { applyGlobalCssTheme } from "../utils/themes";

import {
  initSessionToken,
  initSessionPrepaidMode,
  initSessionInstance,
  initGlobalCssTheme,
  initSessionStoreCode,
  initSessionIsKorea,
  initSessionUseApiV2,
  initSessionCurrentPurchaseId,
  initSessionPricePointId,
  initNewFlow
} from "../redux/modules/session";

import { initSessionVirtualCurreny } from "../redux/modules/paymentOptions";
import { getBcp47Locale, DEFAULT_LOCALE } from "../i18n";
import PaymentError from "../components/PaymentError";
import { DEFAULT_THEME } from "../utils/themes";

export const DEFAULT_STORE_CODE = "lol";
export const DEFAULT_INSTANCE = "rgi";

class Start extends React.Component {
  componentWillMount() {
    const locale = getBcp47Locale() || DEFAULT_LOCALE;
    sessionStorage.setItem("locale", locale);

    const query = queryString.parse(this.props.location.search);

    if (query.pid !== undefined) {
      // Using API v1
      this.props.initSessionToken(query.pid);
      sessionStorage.setItem("sessionToken", query.pid);
    } else {
      // Using API v2
      this.props.initSessionToken(query.s);
      sessionStorage.setItem("sessionToken", query.s);

      this.props.initSessionUseApiV2(true);
      sessionStorage.setItem("useApiV2", true);

      // Setting current purchase ID to null when initializing, this initializes the value in sessionStorage too
      this.props.initSessionCurrentPurchaseId(null);
    }

    if (query.vc !== undefined) {
      this.props.initSessionVirtualCurreny(query.vc.toLowerCase());
      sessionStorage.setItem("virtualCurrencyCode", query.vc.toLowerCase());
    }

    this.props.initSessionPrepaidMode(Boolean(query.prepaid));
    sessionStorage.setItem("prepaidMode", Boolean(query.prepaid));

    const instance = query.i !== undefined ? query.i : DEFAULT_INSTANCE;
    this.props.initSessionInstance(instance);
    sessionStorage.setItem("instance", instance);

    const isKorea = query.k === "1";
    this.props.initSessionIsKorea(isKorea);
    sessionStorage.setItem("isKorea", isKorea);

    const storeCode = query.sc !== undefined ? query.sc : DEFAULT_STORE_CODE;
    this.props.initSessionStoreCode(storeCode);
    sessionStorage.setItem("storeCode", storeCode);

    let cssTheme = query.t !== undefined ? query.t : DEFAULT_THEME;

    // Uncomment for local AB testing
    // const activeExperiments = [process.env.REACT_APP_CHECKOUT_FLOW_EXPERIMENT];
    const activeExperiments = query.experiments !== undefined ? decodeURIComponent(query.experiments).split(",") : [];
    const targetExperiment = process.env.REACT_APP_CHECKOUT_FLOW_EXPERIMENT;

    const isNewFlowActive = targetExperiment && cssTheme === "lol" && activeExperiments.includes(targetExperiment);
    if (isNewFlowActive) {
      this.props.initNewFlow(true);
      sessionStorage.setItem("isNewFlow", true);
      cssTheme = "lolv2";
      this.setThemeCookie("lolv2");
    }

    this.props.initGlobalCssTheme(cssTheme);
    sessionStorage.setItem("cssTheme", cssTheme);
    applyGlobalCssTheme(cssTheme);

    if (query.ppid !== undefined) {
      this.props.initSessionPricePointId(query.ppid);
      sessionStorage.setItem("pricePointId", query.ppid);
    }
  }

  setThemeCookie = (theme) => {
    let options = "";
    if (process.env.REACT_APP_PMC_VERSION !== "dev") {
      // Set domain to .pay.riotgames.com to allow cookie access from gateway pages
      // Set max-age to 10 minutes, to provide a buffer over the 5 minute session timeout
      // Set secure cookie transmission over HTTPS
      options = "domain=.pay.riotgames.com;max-age=600;secure";
    }

    document.cookie = `theme=${theme};${options}`;
  };

  onAckError = () => {
    this.context.router.history.push("/");
  };

  render() {
    // pid for API v1; s for API v2
    const { pid, s, prepaid } = queryString.parse(this.props.location.search);
    if (!pid && !s) {
      return <PaymentError onAckError={this.onAckError} />;
    }

    return Boolean(prepaid) ? <Redirect to="/prepaid" /> : <Redirect to="/" />;
  }
}

Start.contextTypes = {
  router: PropTypes.object
};

Start.propTypes = {
  initSessionToken: PropTypes.func.isRequired,
  initSessionPrepaidMode: PropTypes.func.isRequired,
  initSessionInstance: PropTypes.func.isRequired,
  initGlobalCssTheme: PropTypes.func.isRequired,
  initSessionStoreCode: PropTypes.func.isRequired,
  initSessionIsKorea: PropTypes.func.isRequired,
  initSessionVirtualCurreny: PropTypes.func.isRequired,
  initSessionCurrentPurchaseId: PropTypes.func.isRequired,
  initSessionPricePointId: PropTypes.func.isRequired,
  initNewFlow: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(
  connect(null, {
    initSessionToken,
    initSessionPrepaidMode,
    initSessionInstance,
    initGlobalCssTheme,
    initSessionStoreCode,
    initSessionIsKorea,
    initSessionUseApiV2,
    initSessionCurrentPurchaseId,
    initSessionVirtualCurreny,
    initSessionPricePointId,
    initNewFlow
  })(Start)
);
