import PropTypes from "prop-types";
import React from "react";
import Modal from "react-modal";
import { FormattedMessage } from "react-intl";
import Select from "react-select";
import ModalCloseButton from "./ModalCloseButton";
import { connect } from "react-redux";
import { getSessionIsNewFlow } from "../redux/modules/session";
import "./CountriesModal.css";

const styles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.541176)"
  }
};

class CountriesModal extends React.Component {
  state = {
    country: ""
  };

  componentWillReceiveProps(nextProps) {
    this.setState({ country: nextProps.currentCountry.code3 });
  }

  onCountryChange = (value) => {
    this.setState({ country: value.value });
  };

  selectCountry = () => {
    this.props.onChangeCountry(this.state.country);
  };

  onSelectInputRender = (e) => {
    return <div />;
  };

  render() {
    const { open, countries, contentLabel, onRequestClose, isNewFlow } = this.props;
    return (
      <Modal contentLabel={contentLabel} className="countries-modal" isOpen={open} style={styles}>
        <div className="country-select">
          <ModalCloseButton onClick={onRequestClose} />
          <h2>
            {isNewFlow ? (
              <FormattedMessage id="country_modal_header" defaultMessage="Show Options For" />
            ) : (
              <FormattedMessage id="display_payment_options_for" defaultMessage="Display payment options for" />
            )}
          </h2>
          <div className="select-box">
            <Select
              name="country-select-input"
              options={countries}
              value={this.state.country}
              onChange={this.onCountryChange}
              clearable={false}
              searchable={false}
              inputRenderer={this.onSelectInputRender}
            />
          </div>

          <button className="btn btn-default" onClick={this.selectCountry}>
            <FormattedMessage id="ok" defaultMessage="OK" />
          </button>
        </div>
      </Modal>
    );
  }
}

CountriesModal.propTypes = {
  contentLabel: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  currentCountry: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  onChangeCountry: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  isNewFlow: getSessionIsNewFlow(state)
});

export default connect(mapStateToProps)(CountriesModal);
